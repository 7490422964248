// ** Initial State
const initialState = {
    recipientList: [],
    pendingCampaign: [],
    activeCampaign: [],
    completedCampaign: [],
    campaignInfo: null,
    campaignNotes: [],
    isFetching: false
}

const recipients = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_RECIPIENT_LIST':
            return { ...state, recipientList: action.payload, isFetching: action.isFetching }
        case 'GET_CAMPAIGN_PENDING':
            return { ...state, pendingCampaign: action.payload, isFetching: action.isFetching }  
        case 'GET_CAMPAIGN_ACTIVE':
            return { ...state, activeCampaign: action.payload, isFetching: action.isFetching }  
        case 'GET_CAMPAIGN_COMPLETED':
            return { ...state, completedCampaign: action.payload, isFetching: action.isFetching }  
        case 'GET_CAMPAIGN_DATA':
            return { ...state, campaignInfo: action.payload, isFetching: action.isFetching }
        case 'GET_CAMPAIGN_NOTES':
            return { ...state, campaignNotes: action.payload } 
        default:
            return { ...state }
    }
}

export default recipients